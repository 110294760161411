import React, { useEffect } from "react"
import { navigate } from "gatsby"

const MultiCloudNetworkingRedirectionPage = () => {
  useEffect(() => {
    navigate("/solutions/multi-cloud/multi-cloud-networking")
  }, [])

  return <></>
}

export default MultiCloudNetworkingRedirectionPage
